import axios from 'axios';
import { z } from 'zod';
import { getApiUrl } from '~/api';
import { getAuthToken } from '~/auth';

interface CreateFunctionParams<
  TInputSchema extends z.ZodSchema<any>,
  TOutputSchema extends z.ZodSchema<any>,
> {
  name: string;
  input: TInputSchema;
  output: TOutputSchema;
}

export const createFunction = <
  TInputSchema extends z.ZodSchema<any>,
  TOutputSchema extends z.ZodSchema<any>,
>({
  name,
  input,
}: CreateFunctionParams<TInputSchema, TOutputSchema>) => {
  return async (
    inputData: z.infer<TInputSchema>,
  ): Promise<z.infer<TOutputSchema>> => {
    input.parse(inputData);

    const url = getApiUrl(`/${name}`);
    const authToken = await getAuthToken();
    const result = await axios({
      url,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: inputData,
    });

    return result.data;
  };
};

export const createBasicFunction = <
  TInputSchema extends z.ZodSchema<any>,
  TOutputSchema extends z.ZodSchema<any>,
>({
  name,
  input,
}: CreateFunctionParams<TInputSchema, TOutputSchema>) => {
  return async (
    inputData: z.infer<TInputSchema>,
  ): Promise<z.infer<TOutputSchema>> => {
    input.parse(inputData);

    const url = getApiUrl(`/${name}`);
    const result = await axios({
      url,
      method: 'POST',
      data: inputData,
    });

    return result.data;
  };
};
