'use client';
import { motion } from 'framer-motion';
import { getMetricText, hasMetric } from '~/log';
import { LogWithActivityType } from '~/types';

export const LogCard = ({ log }: { log: LogWithActivityType }) => {
  if (!log.activityType) return <div>No activity type</div>;

  return (
    <motion.div className="flex w-full items-center gap-4 rounded-xl bg-gray-800/50 px-6 py-5">
      <div className="space-y-8">
        <div className="flex items-center text-lg font-extrabold">
          {log.activityType.name}
        </div>

        <div className="flex">
          {hasMetric(log) ? (
            <div className="space-y-1">
              <div className="text-xs font-bold uppercase text-muted-foreground">
                Max
              </div>

              <div className="text-xl font-bold">{getMetricText(log)}</div>
            </div>
          ) : (
            <div className="text-sm text-muted-foreground">
              No progress has been logged yet
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};
