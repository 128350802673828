import { Route, Routes, useLocation } from 'react-router-dom';
import { AuthLayout } from '~/auth/components';
import { CodePage, SignInPage, SignOutPage, SignUpPage } from '~/auth/pages';
import { DashboardLayout } from '~/dashboard/components';
import { DashboardHomePage, DashboardSettingsPage } from '~/dashboard/pages';
import { HomePage } from '~/home/pages';
import {
  DeleteAccountPage,
  LimitedUseDisclosurePage,
  PrivacyPage,
  TermsPage,
} from '~/legal/pages';
import { PublicPage } from '~/public/pages';
import { QueryProvider } from '~/query/components';
import { RootLayout } from '~/root/components';
import { Theme } from '~/theme/components';

export const App = () => {
  const location = useLocation();

  return (
    <QueryProvider>
      <Theme defaultTheme="system">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<RootLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route
              path="/limited-use-disclosure"
              element={<LimitedUseDisclosurePage />}
            />
            <Route path="/delete-account" element={<DeleteAccountPage />} />
          </Route>

          <Route path="/" element={<AuthLayout />}>
            <Route path="/sign-in" element={<SignInPage />} />
            <Route path="/sign-in/code" element={<CodePage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/sign-up/code" element={<CodePage />} />
          </Route>

          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route path="/dashboard" element={<DashboardHomePage />} />
            <Route
              path="/dashboard/settings"
              element={<DashboardSettingsPage />}
            />
          </Route>

          <Route path="/sign-out" element={<SignOutPage />} />

          <Route path="/:username" element={<PublicPage />} />
        </Routes>
      </Theme>
    </QueryProvider>
  );
};
