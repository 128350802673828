import { Outlet } from 'react-router-dom';
import { Header } from '~/components';

export const RootLayout = () => {
  return (
    <>
      <Header />

      <main className="px-8 pb-32 md:px-20 md:pt-10 lg:pb-60">
        <Outlet />
      </main>
    </>
  );
};
