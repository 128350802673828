import { CloudImage } from '~/cloudinary/components';
import { AppleAppStoreButton, Arrow1, Button, Separator } from '~/components';

export const HomePage = () => {
  return (
    <>
      <section className="relative mx-auto mt-10 grid max-w-6xl text-center xl:-mt-20 xl:grid-cols-2 xl:items-center xl:gap-40 xl:text-left">
        <div className="mx-auto max-w-sm md:max-w-lg">
          <h1 className="mb-4 text-balance text-2xl font-bold leading-normal md:text-3xl lg:text-6xl lg:leading-tight">
            Stay focused on your workout
          </h1>

          <p className="mb-10 text-balance text-muted-foreground md:mb-10 md:text-lg lg:mb-12 lg:text-xl lg:leading-relaxed">
            No more manual logging. You simply record your progress by saying:
            <span className="font-medium text-white">
              &quot;<span className="whitespace-nowrap">I just hit</span> 185 on
              bench&quot;
            </span>
            , and Base logs everything for you.
          </p>

          <div className="mx-auto flex max-w-xs flex-col gap-6 md:flex-row md:justify-center md:pr-10">
            <a
              className="block w-full md:w-auto"
              href="https://www.youtube.com/watch?v=8I3DxAWlxZ0"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                className="w-full shrink-0 gap-6 py-8 md:w-56 md:py-9"
                size="md"
                variant="secondary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="-ml-2 size-6 shrink-0"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                    clipRule="evenodd"
                  ></path>
                </svg>

                <span>Watch overview</span>
              </Button>
            </a>

            <AppleAppStoreButton className="w-full md:w-56" />
          </div>
        </div>

        <div className="relative mx-auto mt-10 max-w-[14rem] md:mx-0 md:max-w-none xl:mt-0">
          <CloudImage
            className="mx-auto max-w-96 md:mt-20"
            id="iphone-1_tgke9u"
            alt="Base - iPhone 1"
          />

          <Arrow1 className="absolute -bottom-40 left-1/2 -translate-x-1/2 md:-bottom-48 xl:-bottom-20 xl:-left-32 2xl:-bottom-10 2xl:-left-40" />
        </div>
      </section>

      <section className="relative mx-auto mt-10 grid max-w-6xl gap-20 text-center xl:mt-20 xl:grid-cols-2 xl:items-center xl:gap-40 xl:text-left">
        <div className="relative mx-auto mt-36 max-w-[14rem] md:mx-0 md:max-w-none xl:mt-0">
          <CloudImage
            className="mx-auto mt-10 max-w-96 md:mt-20 xl:mx-0"
            id="iphone-2_wqkd6a"
            alt="Base - iPhone 2"
          />
        </div>

        <div className="mx-auto max-w-sm md:max-w-lg xl:pb-40 xl:pt-24">
          <h2 className="mb-4 text-balance text-2xl font-bold leading-normal md:text-3xl lg:text-5xl lg:leading-tight">
            Turn your progress into motivation
          </h2>

          <p className="mx-auto mb-10 max-w-sm text-muted-foreground md:mb-10 md:text-lg lg:mb-12 lg:text-3xl lg:leading-relaxed xl:mx-0">
            Your progress tells the story of your hard work, inspiring you to
            set bigger goals, and go further in every session.
          </p>
        </div>
      </section>

      <Separator className="mt-8 lg:mt-20 xl:mt-40" />

      <section className="mt-20">
        <div className="mx-auto max-w-sm text-center md:max-w-2xl lg:max-w-3xl lg:pt-20">
          <h3 className="mb-4 text-balance text-2xl font-bold md:mb-8 md:text-4xl lg:mb-10 lg:text-5xl xl:text-6xl xl:leading-tight">
            Get started for free today
          </h3>

          <p className="mx-auto max-w-3xl text-balance text-xl leading-relaxed text-muted-foreground md:text-xl md:leading-relaxed lg:text-3xl lg:leading-normal">
            Base makes logging effortless and progress motivating, so you can
            stay focused and push further to reach your fitness goals faster.
          </p>

          <AppleAppStoreButton className="mt-14 px-10 md:mt-20" />
        </div>
      </section>
    </>
  );
};
