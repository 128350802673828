import { useEffect } from 'react';
import { signOut } from '~/auth';
import { PageLoader } from '~/components';

export const SignOutPage = () => {
  useEffect(() => {
    const fn = async () => {
      await signOut();
    };

    fn();
  }, []);

  return <PageLoader />;
};
