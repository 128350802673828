import { isProduction } from '~/stage';

export const getApiUrl = (path: string) => {
  // return `https://us-ohio-api.basefitness.app${path}`;

  const baseUrl = isProduction()
    ? 'https://api.basefitness.app'
    : 'https://europe-london-dev-api.basefitness.app';

  return `${baseUrl}${path}`;
};
