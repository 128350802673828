import { Button } from '~/components';
import { cn } from '~/style';

export const AppleAppStoreButton = ({ className }: { className?: string }) => {
  return (
    <a href="https://apps.apple.com/us/app/base-gym-workout-tracker/id6502869477">
      <Button className={cn('py-8 md:py-9', className)} size="md">
        <div className="flex items-center gap-6">
          <svg
            className="-ml-2 -mt-1 w-6"
            viewBox="0 0 37 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.7035 23.4274C30.6521 17.862 35.3774 15.1544 35.5934 15.0283C32.9173 11.2242 28.7695 10.7044 27.3119 10.663C23.8282 10.3055 20.449 12.6954 18.6744 12.6954C16.8643 12.6954 14.1316 10.6975 11.1863 10.7562C7.39617 10.8132 3.85048 12.9527 1.90584 16.2751C-2.10741 23.0493 0.885707 33.0043 4.73071 38.4799C6.65409 41.1616 8.90159 44.1559 11.8433 44.0506C14.7213 43.9349 15.7964 42.2616 19.2695 42.2616C22.7106 42.2616 23.7202 44.0506 26.7204 43.9832C29.8091 43.9349 31.7537 41.2894 33.6098 38.5835C35.8325 35.5098 36.7252 32.4828 36.7606 32.3274C36.688 32.3032 30.762 30.0981 30.7035 23.4274Z"
              fill="black"
            />
            <path
              d="M25.0361 7.06087C26.584 5.17348 27.6431 2.60574 27.3491 0C25.1087 0.0967006 22.3068 1.51095 20.6934 3.35689C19.2659 4.98354 17.9907 7.64971 18.3202 10.157C20.8369 10.3401 23.4208 8.9189 25.0361 7.06087Z"
              fill="black"
            />
          </svg>

          <span>Download App</span>
        </div>
      </Button>
    </a>
  );
};
