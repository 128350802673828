import { isUs } from '~/utils';

export const LogPrompts = () => {
  const message = isUs()
    ? 'I just hit 185 lbs on<br/>bench press'
    : 'I just hit 100 kg on<br/>bench press';

  return (
    <div className="mt-2 h-24 text-center text-2xl font-bold leading-relaxed">
      &#8220;
      <span dangerouslySetInnerHTML={{ __html: message }} />
      &#8221;
    </div>
  );
};
