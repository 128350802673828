'use client';

import { LogCard, LogPrompts } from '~/log/components';
import { useLogsMaxQuery } from '~/log/hooks';

export const DashboardHomePage = () => {
  const { logs, hasFetched, hasLogs } = useLogsMaxQuery({
    uniqueKey: 'all',
  });

  return (
    <>
      {hasFetched && (
        <div className="record-padding mx-auto max-w-3xl px-4 py-5">
          {hasLogs && (
            <div className="grid gap-3">
              {logs.map((log) => {
                return <LogCard key={log.id} log={log} />;
              })}
            </div>
          )}

          {!hasLogs && (
            <>
              <div className="mt-6 px-4">
                <div className="text-center text-3xl font-semibold">
                  Log your progress
                </div>

                <div className="mx-auto mt-20 max-w-sm text-balance text-center leading-relaxed text-muted-foreground">
                  Get started by saying:
                </div>

                <LogPrompts />

                <div className="mx-auto mt-20 max-w-sm text-balance text-center text-sm leading-relaxed text-muted-foreground">
                  Please note that it may take a few minutes for your latest
                  data to show here
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
