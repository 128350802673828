'use client';
import { useEffect } from 'react';

export const Redirect = ({ path }: { path: string }) => {
  useEffect(() => {
    window.location.href = path;
  }, []);

  return null;
};
