export const PrivacyPage = () => {
  return (
    <div className="m-auto w-full max-w-xl space-y-6">
      <h1 className="text-2xl md:text-4xl">Privacy Policy</h1>

      <p className="text-gray-300">
        Last updated 20 May 2024
        <br />
        <br />
        This privacy notice for Base Fitness. (&quot;Company,&quot;
        &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), describes how and
        why we might collect, store, use, and/or share (&quot;process&quot;)
        your information when you use our services (&quot;Services&quot;), such
        as when you:
        <br />
        <br />
        Visit our website at basefitness.app, or any website of ours that links
        to this privacy notice Download and use our mobile application (Base
        Fitness), or any other application of ours that links to this privacy
        notice Engage with us in other related ways, including any sales,
        marketing, or events Questions or concerns? Reading this privacy notice
        will help you understand your privacy rights and choices. If you do not
        agree with our policies and practices, please do not use our Services.
        If you still have any questions or concerns, please contact us at
        hello@basefitness.app.
        <br />
        <br />
        SUMMARY OF KEY POINTS
        <br />
        <br />
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our table of contents below to find
        the section you are looking for. You can also click here to go directly
        to our table of contents. What personal information do we process? When
        you visit, use, or navigate our Services, we may process personal
        information depending on how you interact with Base Fitness and the
        Services, the choices you make, and the products and features you use.
        Click here to learn more. Do we process any sensitive personal
        information? We do not process sensitive personal information.
        <br />
        <br />
        Do we receive any information from third parties? We may receive
        information from public databases, marketing partners, social media
        platforms, and other outside sources. Click here to learn more. How do
        we process your information? We process your information to provide,
        improve, and administer our Services, communicate with you, for security
        and fraud prevention, and to comply with law. We may also process your
        information for other purposes with your consent. We process your
        information only when we have a valid legal reason to do so. Click here
        to learn more. In what situations and with which parties do we share
        personal information? We may share information in specific situations
        and with specific third parties. Click here to learn more. What are your
        rights? Depending on where you are located geographically, the
        applicable privacy law may mean you have certain rights regarding your
        personal information. Click here to learn more. How do you exercise your
        rights? The easiest way to exercise your rights is by filling out our
        data subject request form available here, or by contacting us. We will
        consider and act upon any request in accordance with applicable data
        protection laws. Want to learn more about what Base Fitness does with
        any information we collect? Click here to review the notice in full.
        <br />
        <br />
        TABLE OF CONTENTS
        <br />
        <br />
        1. WHAT INFORMATION DO WE COLLECT? 2. HOW DO WE PROCESS YOUR
        INFORMATION? 3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
        INFORMATION? 5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? 6.
        HOW DO WE HANDLE YOUR SOCIAL LOGINS? 7. IS YOUR INFORMATION TRANSFERRED
        INTERNATIONALLY? 8. HOW LONG DO WE KEEP YOUR INFORMATION? 10. CONTROLS
        FOR DO-NOT-TRACK FEATURES 11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
        PRIVACY RIGHTS? 12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        13. DO WE MAKE UPDATES TO THIS NOTICE? 14. HOW CAN YOU CONTACT US ABOUT
        THIS NOTICE? 15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
        COLLECT FROM YOU? 1. WHAT INFORMATION DO WE COLLECT?
        <br />
        <br />
        Personal information you disclose to us
        <br />
        <br />
        In Short: We collect personal information that you provide to us.
        <br />
        <br />
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
        <br />
        <br />
        Personal Information Provided by You. The personal information that we
        collect depends on the context of your interactions with us and the
        Services, the choices you make, and the products and features you use.
        The personal information we collect may include the following:
        <br />
        <br />
        names phone numbers email addresses usernames passwords contact
        preferences contact or authentication data billing addresses
        debit/credit card numbers mailing addresses job titles Sensitive
        Information. We do not process sensitive information.
        <br />
        <br />
        Social Media Login Data. We may provide you with the option to register
        with us using your existing social media account details, like your
        Facebook, Twitter, or other social media account. If you choose to
        register in this way, we will collect the information described in the
        section called &quot;&quot; below. Application Data. If you use our
        application(s), we also may collect the following information if you
        choose to provide us with access or permission:
        <br />
        <br />
        Geolocation Information. We may request access or permission to track
        location-based information from your mobile device, either continuously
        or while you are using our mobile application(s), to provide certain
        location-based services. If you wish to change our access or
        permissions, you may do so in your device&apos;s settings. Mobile Device
        Access. We may request access or permission to certain features from
        your mobile device, including your mobile device&apos;s bluetooth,
        calendar, camera, contacts, microphone, sensors, sms messages,
        reminders, social media accounts, storage, and other features. If you
        wish to change our access or permissions, you may do so in your
        device&apos;s settings. Mobile Device Data. We automatically collect
        device information (such as your mobile device ID, model, and
        manufacturer), operating system, version information and system
        configuration information, device and application identification
        numbers, browser type and version, hardware model Internet service
        provider and/or mobile carrier, and Internet Protocol (IP) address (or
        proxy server). If you are using our application(s), we may also collect
        information about the phone network associated with your mobile device,
        your mobile device&apos;s operating system or platform, the type of
        mobile device you use, your mobile device&apos;s unique device ID, and
        information about the features of our application(s) you accessed. Push
        Notifications. We may request to send you push notifications regarding
        your account or certain features of the application(s). If you wish to
        opt out from receiving these types of communications, you may turn them
        off in your device&apos;s settings. This information is primarily needed
        to maintain the security and operation of our application(s), for
        troubleshooting, and for our internal analytics and reporting purposes.
        <br />
        <br />
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
        <br />
        <br />
        Information automatically collected
        <br />
        <br />
        In Short: Some information — such as your Internet Protocol (IP) address
        and/or browser and device characteristics — is collected automatically
        when you visit our Services.
        <br />
        <br />
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
        <br />
        <br />
        Like many businesses, we also collect information through cookies and
        similar technologies.
        <br />
        <br />
        The information we collect includes:
        <br />
        <br />
        Log and Usage Data. Log and usage data is service-related, diagnostic,
        usage, and performance information our servers automatically collect
        when you access or use our Services and which we record in log files.
        Depending on how you interact with us, this log data may include your IP
        address, device information, browser type, and settings and information
        about your activity in the Services (such as the date/time stamps
        associated with your usage, pages and files viewed, searches, and other
        actions you take such as which features you use), device event
        information (such as system activity, error reports (sometimes called
        &quot;crash dumps&quot;), and hardware settings). Device Data. We
        collect device data such as information about your computer, phone,
        tablet, or other device you use to access the Services. Depending on the
        device used, this device data may include information such as your IP
        address (or proxy server), device and application identification
        numbers, location, browser type, hardware model, Internet service
        provider and/or mobile carrier, operating system, and system
        configuration information. Location Data. We collect location data such
        as information about your device&apos;s location, which can be either
        precise or imprecise. How much information we collect depends on the
        type and settings of the device you use to access the Services. For
        example, we may use GPS and other technologies to collect geolocation
        data that tells us your current location (based on your IP address). You
        can opt out of allowing us to collect this information either by
        refusing access to the information or by disabling your Location setting
        on your device. However, if you choose to opt out, you may not be able
        to use certain aspects of the Services. Information collected from other
        sources
        <br />
        <br />
        In Short: We may collect limited data from public databases, marketing
        partners, social media platforms, and other outside sources.
        <br />
        <br />
        In order to enhance our ability to provide relevant marketing, offers,
        and services to you and update our records, we may obtain information
        about you from other sources, such as public databases, joint marketing
        partners, affiliate programs, data providers, social media platforms,
        and from other third parties. This information includes mailing
        addresses, job titles, email addresses, phone numbers, intent data (or
        user behavior data), Internet Protocol (IP) addresses, social media
        profiles, social media URLs, and custom profiles, for purposes of
        targeted advertising and event promotion. If you interact with us on a
        social media platform using your social media account (e.g., Facebook or
        Twitter), we receive personal information about you such as your name,
        email address, and gender. Any personal information that we collect from
        your social media account depends on your social media account&apos;s
        privacy settings.
        <br />
        <br />
        2. HOW DO WE PROCESS YOUR INFORMATION?
        <br />
        <br />
        In Short: We process your information to provide, improve, and
        administer our Services, communicate with you, for security and fraud
        prevention, and to comply with law. We may also process your information
        for other purposes with your consent.
        <br />
        <br />
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
        <br />
        <br />
        To facilitate account creation and authentication and otherwise manage
        user accounts. We may process your information so you can create and log
        in to your account, as well as keep your account in working order. To
        save or protect an individual&apos;s vital interest. We may process your
        information when necessary to save or protect an individual&apos;s vital
        interest, such as to prevent harm. 3. WHAT LEGAL BASES DO WE RELY ON TO
        PROCESS YOUR INFORMATION?
        <br />
        <br />
        In Short: We only process your personal information when we believe it
        is necessary and we have a valid legal reason (i.e., legal basis) to do
        so under applicable law, like with your consent, to comply with laws, to
        provide you with services to enter into or fulfill our contractual
        obligations, to protect your rights, or to fulfill our legitimate
        business interests.
        <br />
        <br />
        If you are located in the EU or UK, this section applies to you.
        <br />
        <br />
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your
        personal information. As such, we may rely on the following legal bases
        to process your personal information:
        <br />
        <br />
        Consent. We may process your information if you have given us permission
        (i.e., consent) to use your personal information for a specific purpose.
        You can withdraw your consent at any time. Click here to learn more.
        Legal Obligations. We may process your information where we believe it
        is necessary for compliance with our legal obligations, such as to
        cooperate with a law enforcement body or regulatory agency, exercise or
        defend our legal rights, or disclose your information as evidence in
        litigation in which we are involved. Vital Interests. We may process
        your information where we believe it is necessary to protect your vital
        interests or the vital interests of a third party, such as situations
        involving potential threats to the safety of any person. In legal terms,
        we are generally the &quot;data controller&quot; under European data
        protection laws of the personal information described in this privacy
        notice, since we determine the means and/or purposes of the data
        processing we perform. This privacy notice does not apply to the
        personal information we process as a &quot;data processor&quot; on
        behalf of our customers. In those situations, the customer that we
        provide services to and with whom we have entered into a data processing
        agreement is the &quot;data controller&quot; responsible for your
        personal information, and we merely process your information on their
        behalf in accordance with your instructions. If you want to know more
        about our customers&apos; privacy practices, you should read their
        privacy policies and direct any questions you have to them.
        <br />
        <br />
        If you are located in Canada, this section applies to you.
        <br />
        <br />
        We may process your information if you have given us specific permission
        (i.e., express consent) to use your personal information for a specific
        purpose, or in situations where your permission can be inferred (i.e.,
        implied consent). You can withdraw your consent at any time. Click here
        to learn more. In some exceptional cases, we may be legally permitted
        under applicable law to process your information without your consent,
        including, for example:
        <br />
        <br />
        If collection is clearly in the interests of an individual and consent
        cannot be obtained in a timely way For investigations and fraud
        detection and prevention For business transactions provided certain
        conditions are met If it is contained in a witness statement and the
        collection is necessary to assess, process, or settle an insurance claim
        For identifying injured, ill, or deceased persons and communicating with
        next of kin If we have reasonable grounds to believe an individual has
        been, is, or may be victim of financial abuse If it is reasonable to
        expect collection and use with consent would compromise the availability
        or the accuracy of the information and the collection is reasonable for
        purposes related to investigating a breach of an agreement or a
        contravention of the laws of Canada or a province If disclosure is
        required to comply with a subpoena, warrant, court order, or rules of
        the court relating to the production of records If it was produced by an
        individual in the course of their employment, business, or profession
        and the collection is consistent with the purposes for which the
        information was produced If the collection is solely for journalistic,
        artistic, or literary purposes If the information is publicly available
        and is specified by the regulations
        <br />
        <br />
        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        <br />
        <br />
        In Short: We may share information in specific situations described in
        this section and/or with the following third parties.
        <br />
        <br />
        We may need to share your personal information in the following
        situations:
        <br />
        <br />
        Business Transfers. We may share or transfer your information in
        connection with, or during negotiations of, any merger, sale of company
        assets, financing, or acquisition of all or a portion of our business to
        another company. Affiliates. We may share your information with our
        affiliates, in which case we will require those affiliates to honor this
        privacy notice. Affiliates include our parent company and any
        subsidiaries, joint venture partners, or other companies that we control
        or that are under common control with us. Business Partners. We may
        share your information with our business partners to offer you certain
        products, services, or promotions. Offer Wall. Our application(s) may
        display a third-party hosted &quot;offer wall.&quot; Such an offer wall
        allows third-party advertisers to offer virtual currency, gifts, or
        other items to users in return for the acceptance and completion of an
        advertisement offer. Such an offer wall may appear in our application(s)
        and be displayed to you based on certain data, such as your geographic
        area or demographic information. When you click on an offer wall, you
        will be brought to an external website belonging to other persons and
        will leave our application(s). A unique identifier, such as your user
        ID, will be shared with the offer wall provider in order to prevent
        fraud and properly credit your account with the relevant reward.
        <br />
        <br />
        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        <br />
        <br />
        In Short: We may use cookies and other tracking technologies to collect
        and store your information.
        <br />
        <br />
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
        <br />
        <br />
        6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        <br />
        <br />
        In Short: If you choose to register or log in to our Services using a
        social media account, we may have access to certain information about
        you.
        <br />
        <br />
        Our Services offer you the ability to register and log in using your
        third-party social media account details (like your Facebook or Twitter
        logins). Where you choose to do this, we will receive certain profile
        information about you from your social media provider. The profile
        information we receive may vary depending on the social media provider
        concerned, but will often include your name, email address, friends
        list, and profile picture, as well as other information you choose to
        make public on such a social media platform.
        <br />
        <br />
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to you
        on the relevant Services. Please note that we do not control, and are
        not responsible for, other uses of your personal information by your
        third-party social media provider. We recommend that you review their
        privacy notice to understand how they collect, use, and share your
        personal information, and how you can set your privacy preferences on
        their sites and apps.
        <br />
        <br />
        7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        <br />
        <br />
        In Short: We may transfer, store, and process your information in
        countries other than your own.
        <br />
        <br />
        Our servers are located in. If you are accessing our Services from
        outside, please be aware that your information may be transferred to,
        stored, and processed by us in our facilities and by those third parties
        with whom we may share your personal information (see &quot;WHEN AND
        WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?&quot; above), in and
        other countries. If you are a resident in the European Economic Area
        (EEA) or United Kingdom (UK), then these countries may not necessarily
        have data protection laws or other similar laws as comprehensive as
        those in your country. However, we will take all necessary measures to
        protect your personal information in accordance with this privacy notice
        and applicable law.
        <br />
        <br />
        8. HOW LONG DO WE KEEP YOUR INFORMATION?
        <br />
        <br />
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy notice unless otherwise required
        by law.
        <br />
        <br />
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than .
        <br />
        <br />
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
        <br />
        <br />
        9. WHAT ARE YOUR PRIVACY RIGHTS?
        <br />
        <br />
        In Short: In some regions, such as the European Economic Area (EEA),
        United Kingdom (UK), and Canada, you have rights that allow you greater
        access to and control over your personal information. You may review,
        change, or terminate your account at any time.
        <br />
        <br />
        In some regions (like the EEA, UK, and Canada), you have certain rights
        under applicable data protection laws. These may include the right (i)
        to request access and obtain a copy of your personal information, (ii)
        to request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. You can make such a request by
        contacting us by using the contact details provided in the section
        &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below. We will
        consider and act upon any request in accordance with applicable data
        protection laws.
        <br />
        <br />
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your local data protection supervisory authority. You can
        find their contact details here:
        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        If you are located in Switzerland, the contact details for the data
        protection authorities are available here:
        https://www.edoeb.admin.ch/edoeb/en/home.html. Withdrawing your consent:
        If we are relying on your consent to process your personal information,
        which may be express and/or implied consent depending on the applicable
        law, you have the right to withdraw your consent at any time. You can
        withdraw your consent at any time by contacting us by using the contact
        details provided in the section &quot;HOW CAN YOU CONTACT US ABOUT THIS
        NOTICE?&quot; below. However, please note that this will not affect the
        lawfulness of the processing before its withdrawal nor, when applicable
        law allows, will it affect the processing of your personal information
        conducted in reliance on lawful processing grounds other than consent.
        <br />
        <br />
        Account Information
        <br />
        <br />
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
        <br />
        <br />
        Log in to your account settings and update your user account. Upon your
        request to terminate your account, we will deactivate or delete your
        account and information from our active databases. However, we may
        retain some information in our files to prevent fraud, troubleshoot
        problems, assist with any investigations, enforce our legal terms and/or
        comply with applicable legal requirements.
        <br />
        <br />
        Cookies and similar technologies: Most Web browsers are set to accept
        cookies by default. If you prefer, you can usually choose to set your
        browser to remove cookies and to reject cookies. If you choose to remove
        cookies or reject cookies, this could affect certain features or
        services of our Services. To opt out of interest-based advertising by
        advertisers on our Services visit http://www.aboutads.info/choices/.
        <br />
        <br />
        10. CONTROLS FOR DO-NOT-TRACK FEATURES
        <br />
        <br />
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting
        you can activate to signal your privacy preference not to have data
        about your online browsing activities monitored and collected. At this
        stage no uniform technology standard for recognizing and implementing
        DNT signals has been finalized. As such, we do not currently respond to
        DNT browser signals or any other mechanism that automatically
        communicates your choice not to be tracked online. If a standard for
        online tracking is adopted that we must follow in the future, we will
        inform you about that practice in a revised version of this privacy
        notice.
        <br />
        <br />
        11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        <br />
        <br />
        In Short: Yes, if you are a resident of California, you are granted
        specific rights regarding access to your personal information.
        <br />
        <br />
        California Civil Code Section 1798.83, also known as the &quot;Shine The
        Light&quot; law, permits our users who are California residents to
        request and obtain from us, once a year and free of charge, information
        about categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
        <br />
        <br />
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g., backups, etc.).
        <br />
        <br />
        CCPA Privacy Notice
        <br />
        <br />
        The California Code of Regulations defines a &quot;resident&quot; as:
        <br />
        <br />
        (1) every individual who is in the State of California for other than a
        temporary or transitory purpose and
        <br />
        <br />
        (2) every individual who is domiciled in the State of California who is
        outside the State of California for a temporary or transitory purpose
        <br />
        <br />
        All other individuals are defined as &quot;non-residents.&quot;
        <br />
        <br />
        If this definition of &quot;resident&quot; applies to you, we must
        adhere to certain rights and obligations regarding your personal
        information.
        <br />
        <br />
        What categories of personal information do we collect?
        <br />
        <br />
        We have collected the following categories of personal information in
        the past twelve (12) months:
        <br />
        <br />
        Category Examples Collected
        <br />
        <br />
        A. Identifiers
        <br />
        <br />
        Contact details, such as real name, alias, postal address, telephone or
        mobile contact number, unique personal identifier, online identifier,
        Internet Protocol address, email address, and account name
        <br />
        <br />
        NO
        <br />
        <br />
        B. Personal information categories listed in the California Customer
        Records statute
        <br />
        <br />
        Name, contact information, education, employment, employment history,
        and financial information
        <br />
        <br />
        NO
        <br />
        <br />
        C. Protected classification characteristics under California or federal
        law
        <br />
        <br />
        Gender and date of birth
        <br />
        <br />
        NO
        <br />
        <br />
        D. Commercial information
        <br />
        <br />
        Transaction information, purchase history, financial details, and
        payment information
        <br />
        <br />
        NO
        <br />
        <br />
        E. Biometric information
        <br />
        <br />
        Fingerprints and voiceprints
        <br />
        <br />
        NO
        <br />
        <br />
        F. Internet or other similar network activity
        <br />
        <br />
        Browsing history, search history, online behavior, interest data, and
        interactions with our and other websites, applications, systems, and
        advertisements
        <br />
        <br />
        NO
        <br />
        <br />
        G. Geolocation data
        <br />
        <br />
        Device location
        <br />
        <br />
        NO
        <br />
        <br />
        H. Audio, electronic, visual, thermal, olfactory, or similar information
        <br />
        <br />
        Images and audio, video or call recordings created in connection with
        our business activities
        <br />
        <br />
        NO
        <br />
        <br />
        I. Professional or employment-related information
        <br />
        <br />
        Business contact details in order to provide you our Services at a
        business level or job title, work history, and professional
        qualifications if you apply for a job with us
        <br />
        <br />
        NO
        <br />
        <br />
        J. Education Information
        <br />
        <br />
        Student records and directory information
        <br />
        <br />
        NO
        <br />
        <br />
        K. Inferences drawn from other personal information
        <br />
        <br />
        Inferences drawn from any of the collected personal information listed
        above to create a profile or summary about, for example, an
        individual&apos;s preferences and characteristics
        <br />
        <br />
        NO
        <br />
        <br />
        L. Sensitive Personal Information NO
        <br />
        <br />
        We may also collect other personal information outside of these
        categories through instances where you interact with us in person,
        online, or by phone or mail in the context of:
        <br />
        <br />
        Receiving help through our customer support channels; Participation in
        customer surveys or contests; and Facilitation in the delivery of our
        Services and to respond to your inquiries. How do we use and share your
        personal information?
        <br />
        <br />
        More information about our data collection and sharing practices can be
        found in this privacy notice.
        <br />
        <br />
        You may contact us or by referring to the contact details at the bottom
        of this document.
        <br />
        <br />
        If you are using an authorized agent to exercise your right to opt out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
        <br />
        <br />
        Will your information be shared with anyone else?
        <br />
        <br />
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf, following the same strict privacy protection
        obligations mandated by the CCPA.
        <br />
        <br />
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be &quot;selling&quot; of your
        personal information.
        <br />
        <br />
        Base Fitness has not disclosed, sold, or shared any personal information
        to third parties for a business or commercial purpose in the preceding
        twelve (12) months. Base Fitness will not sell or share personal
        information in the future belonging to website visitors, users, and
        other consumers.
        <br />
        <br />
        Your rights with respect to your personal data
        <br />
        <br />
        Right to request deletion of the data — Request to delete
        <br />
        <br />
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
        <br />
        <br />
        Right to be informed — Request to know
        <br />
        <br />
        Depending on the circumstances, you have a right to know:
        <br />
        <br />
        whether we collect and use your personal information; the categories of
        personal information that we collect; the purposes for which the
        collected personal information is used; whether we sell or share
        personal information to third parties; the categories of personal
        information that we sold, shared, or disclosed for a business purpose;
        the categories of third parties to whom the personal information was
        sold, shared, or disclosed for a business purpose; the business or
        commercial purpose for collecting, selling, or sharing personal
        information; and the specific pieces of personal information we
        collected about you. In accordance with applicable law, we are not
        obligated to provide or delete consumer information that is
        de-identified in response to a consumer request or to re-identify
        individual data to verify a consumer request.
        <br />
        <br />
        Right to Non-Discrimination for the Exercise of a Consumer&apos;s
        Privacy Rights
        <br />
        <br />
        We will not discriminate against you if you exercise your privacy
        rights.
        <br />
        <br />
        Right to Limit Use and Disclosure of Sensitive Personal Information
        <br />
        <br />
        We do not process consumer&apos;s sensitive personal information.
        <br />
        <br />
        Verification process
        <br />
        <br />
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g., phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
        <br />
        <br />
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
        <br />
        <br />
        Other privacy rights
        <br />
        <br />
        You may object to the processing of your personal information. You may
        request correction of your personal data if it is incorrect or no longer
        relevant, or ask to restrict the processing of the information. You can
        designate an authorized agent to make a request under the CCPA on your
        behalf. We may deny a request from an authorized agent that does not
        submit proof that they have been validly authorized to act on your
        behalf in accordance with the CCPA. You may request to opt out from
        future selling or sharing of your personal information to third parties.
        Upon receiving an opt-out request, we will act upon the request as soon
        as feasibly possible, but no later than fifteen (15) days from the date
        of the request submission. To exercise these rights, you can contact us
        or by referring to the contact details at the bottom of this document.
        If you have a complaint about how we handle your data, we would like to
        hear from you.
        <br />
        <br />
        12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        <br />
        <br />
        In Short: Yes, if you are a resident of Virginia, you may be granted
        specific rights regarding access to and use of your personal
        information.
        <br />
        <br />
        Virginia CDPA Privacy Notice
        <br />
        <br />
        Under the Virginia Consumer Data Protection Act (CDPA):
        <br />
        <br />
        &quot;Consumer&quot; means a natural person who is a resident of the
        Commonwealth acting only in an individual or household context. It does
        not include a natural person acting in a commercial or employment
        context.
        <br />
        <br />
        &quot;Personal data&quot; means any information that is linked or
        reasonably linkable to an identified or identifiable natural person.
        &quot;Personal data&quot; does not include de-identified data or
        publicly available information.
        <br />
        <br />
        &quot;Sale of personal data&quot; means the exchange of personal data
        for monetary consideration.
        <br />
        <br />
        If this definition &quot;consumer&quot; applies to you, we must adhere
        to certain rights and obligations regarding your personal data.
        <br />
        <br />
        The information we collect, use, and disclose about you will vary
        depending on how you interact with Base Fitness and our Services. To
        find out more, please visit the following links:
        <br />
        <br />
        Personal data we collect How we use your personal data When and with
        whom we share your personal data Your rights with respect to your
        personal data
        <br />
        <br />
        Right to be informed whether or not we are processing your personal data
        Right to access your personal data Right to correct inaccuracies in your
        personal data Right to request deletion of your personal data Right to
        obtain a copy of the personal data you previously shared with us Right
        to opt out of the processing of your personal data if it is used for
        targeted advertising, the sale of personal data, or profiling in
        furtherance of decisions that produce legal or similarly significant
        effects (&quot;profiling&quot;) Base Fitness has not sold any personal
        data to third parties for business or commercial purposes. Base Fitness
        will not sell personal data in the future belonging to website visitors,
        users, and other consumers.
        <br />
        <br />
        Exercise your rights provided under the Virginia CDPA
        <br />
        <br />
        More information about our data collection and sharing practices can be
        found in this privacy notice.
        <br />
        <br />
        You may contact us by email at __________, by visiting our data subject
        request form, or by referring to the contact details at the bottom of
        this document. If you are using an authorized agent to exercise your
        rights, we may deny a request if the authorized agent does not submit
        proof that they have been validly authorized to act on your behalf.
        <br />
        <br />
        Verification process
        <br />
        <br />
        We may request that you provide additional information reasonably
        necessary to verify you and your consumer&apos;s request. If you submit
        the request through an authorized agent, we may need to collect
        additional information to verify your identity before processing your
        request.
        <br />
        <br />
        Upon receiving your request, we will respond without undue delay, but in
        all cases, within forty-five (45) days of receipt. The response period
        may be extended once by forty-five (45) additional days when reasonably
        necessary. We will inform you of any such extension within the initial
        45-day response period, together with the reason for the extension.
        <br />
        <br />
        Right to appeal
        <br />
        <br />
        If we decline to take action regarding your request, we will inform you
        of our decision and reasoning behind it. If you wish to appeal our
        decision, please email us at hello@basefitness.app. Within sixty (60)
        days of receipt of an appeal, we will inform you in writing of any
        action taken or not taken in response to the appeal, including a written
        explanation of the reasons for the decisions. If your appeal if denied,
        you may contact the Attorney General to submit a complaint.
        <br />
        <br />
        13. DO WE MAKE UPDATES TO THIS NOTICE?
        <br />
        <br />
        In Short: Yes, we will update this notice as necessary to stay compliant
        with relevant laws.
        <br />
        <br />
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated &quot;Revised&quot; date and the updated
        version will be effective as soon as it is accessible. If we make
        material changes to this privacy notice, we may notify you either by
        prominently posting a notice of such changes or by directly sending you
        a notification. We encourage you to review this privacy notice
        frequently to be informed of how we are protecting your information.
        <br />
        <br />
        14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        <br />
        <br />
        If you have questions or comments about this notice, you may email us at
        hello@basefitness.app.
        <br />
        <br />
        15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        <br />
        <br />
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please email us.
        <br />
        <br />
        16. GOOGLE DATA
        <br />
        <br />
        Base Fitness respects your privacy and is committed to using your Google
        data, specifically for reading and analyzing emails and files in Google
        Drive, responsibly and securely. By granting Base Fitness access to this
        data, you authorize us to retrieve relevant information, insights, and
        summaries to optimize our AI-assisted services. Rest assured, your data
        will be encrypted and protected from unauthorized access. We will only
        use the data necessary for our services and will not share it with third
        parties without your explicit consent, except as required by law. You
        have the option to revoke access at any time, and should you choose to
        discontinue using Base Fitness, we will promptly delete your associated
        Google data upon request. Your trust is paramount to us, and we are
        committed to safeguarding your data and privacy.
        <br />
        <br />
        17. LIMITED USE DISCLOSURE
        <br />
        <br />
        Please{' '}
        <a
          className="text-blue-500 hover:underline"
          href="/limited-use-disclosure"
        >
          click here
        </a>{' '}
        to view the Limited Use Disclosure.
        <br />
        <br />
        18. Google Account Integration
        <br />
        <br />
        Base Fitness is dedicated to respecting your privacy and ensuring a
        transparent understanding of how we handle your data when you connect
        your Google account to our platform. By linking your Google account with
        Base Fitness, you enable us to access and analyze your emails for the
        purpose of facilitating our AI-powered question-answering functionality.
        <br />
        <br />
        18.1 - Data Shared with Third-Party AI Models:
        <br />
        When you use Base Fitness, we may share specific data from your Google
        account with third-party AI models integrated into our platform. This
        data sharing is done solely to enhance the accuracy and effectiveness of
        our question-answering feature. The information shared with these AI
        models is limited to the necessary details required for delivering
        precise answers to your queries.
        <br />
        <br />
        18.2 - Scope of Shared Data:
        <br />
        The data shared with third-party AI models includes the content of your
        emails that are relevant to the questions you ask within the Base
        Fitness platform. We do not share any unrelated or sensitive information
        with these AI models, and your personal data remains securely protected
        within the confines of Base Fitness.
        <br />
        <br />
        18.3 - Commitment to Data Security:
        <br />
        Rest assured, we take data security seriously, and the confidentiality
        of your personal information is of paramount importance to us. We employ
        industry-standard security measures to safeguard your data against
        unauthorized access, disclosure, or misuse.
        <br />
        <br />
        18.4 - User Consent:
        <br />
        Your decision to connect your Google account with Base Fitness is
        entirely voluntary, and you maintain complete control over your data
        sharing preferences. You can revoke Base Fitness&apos;s access to your
        Google account at any time through your Google account settings.
        <br />
        <br />
        18.5 - Third-Party Model Providers:
        <br />
        Base Fitness works with trusted third-party AI model providers, who
        abide by strict privacy and security guidelines. These partners only
        process data in accordance with our instructions and for the specific
        purposes outlined in this privacy policy.
        <br />
        We encourage you to review the complete Privacy Policy for a
        comprehensive understanding of how we collect, use, and protect your
        data. At Base Fitness, we remain committed to transparent data practices
        and providing you with an exceptional user experience while safeguarding
        your privacy.
      </p>
    </div>
  );
};
