import { z } from "zod";
import { createBasicFunction, createFunction } from "~/api";
import * as types from "~/types";

export const queryLogsSchema = {
  name: "queryLogs",
  input: z.object({
    startDate: z.number(),
    endDate: z.number(),
    category: z.string(),
    activityTypeNameId: z.string(),
    type: z.string().nullable().optional(),
    limit: z.number().optional(),
    version: z.object({
      text: z.string(),
      major: z.number(),
      minor: z.number(),
      webVersion: z.number(),
    }),
  }),
  output: z.object({
    logs: z.array(types.LogSchema),
  }),
};

export const queryLogs = createFunction(queryLogsSchema);

export const queryLogsMaxSchema = {
  name: "queryLogsMax",
  input: z.object({
    category: z.string().optional(),
    version: z.object({
      text: z.string(),
      major: z.number(),
      minor: z.number(),
      webVersion: z.number(),
    }),
  }),
  output: z.object({
    logs: z.array(types.LogSchema),
  }),
};

export const queryLogsMax = createFunction(queryLogsMaxSchema);

export const queryLogsMaxPublicSchema = {
  name: "queryLogsMaxPublic",
  input: z.object({
    username: z.string(),
    category: z.string().optional(),
    version: z.object({
      text: z.string(),
      major: z.number(),
      minor: z.number(),
      webVersion: z.number(),
    }),
  }),
  output: z.object({
    logs: z.array(types.LogSchema),
  }),
};

export const queryLogsMaxPublic = createBasicFunction(queryLogsMaxPublicSchema);

export const queryLogMaxSchema = {
  name: "queryLogMax",
  input: z.object({
    activityTypeNameId: z.string(),
    version: z.object({
      text: z.string(),
      major: z.number(),
      minor: z.number(),
      webVersion: z.number(),
    }),
  }),
  output: z.object({
    log: z.union([types.LogWithActivityTypeSchema, z.null()]),
  }),
};

export const queryLogMax = createFunction(queryLogMaxSchema);

export const getUserSchema = {
  name: "getUser",
  input: z.object({}),
  output: z.object({
    user: z.union([types.UserSchema, z.null()]),
  }),
};

export const getUser = createFunction(getUserSchema);

export const getPublicUserSchema = {
  name: "getPublicUser",
  input: z.object({
    username: z.string(),
  }),
  output: z.object({
    user: z.union([types.PublicUserSchema, z.null()]),
  }),
};

export const getPublicUser = createBasicFunction(getPublicUserSchema);

export const signUpSchema = {
  name: "signUp",
  input: z.object({
    name: z.string(),
    email: z.string(),
  }),
  output: z.object({
    error: z.union([z.string(), z.null()]),
    ok: z.boolean(),
  }),
};

export const signUp = createBasicFunction(signUpSchema);

export const signInSchema = {
  name: "signIn",
  input: z.object({
    email: z.string(),
  }),
  output: z.object({
    error: z.union([z.string(), z.null()]),
  }),
};

export const signIn = createBasicFunction(signInSchema);

export const verifyAuthCodeSchema = {
  name: "verifyAuthCode",
  input: z.object({
    source: z.enum(["web", "app"]).optional(),
    code: z.string(),
  }),
  output: z.object({
    error: z.union([z.string(), z.null()]),
    authToken: z.union([z.string(), z.null()]),
    user: z.union([types.UserSchema, z.null()]),
  }),
};

export const verifyAuthCode = createBasicFunction(verifyAuthCodeSchema);

export const deleteAccountSchema = {
  name: "deleteAccount",
  input: z.object({}),
  output: z.object({}),
};

export const deleteAccount = createFunction(deleteAccountSchema);

export const updateUsernameSchema = {
  name: "updateUsername",
  input: z.object({
    username: z.string(),
  }),
  output: z.object({
    error: z.union([z.string(), z.null()]),
    user: types.UserSchema,
  }),
};

export const updateUsername = createFunction(updateUsernameSchema);

export const updateIsPublicSchema = {
  name: "updateIsPublic",
  input: z.object({
    isPublic: z.boolean(),
  }),
  output: z.object({
    user: types.UserSchema,
  }),
};

export const updateIsPublic = createFunction(updateIsPublicSchema);
