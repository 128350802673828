import { createSelectSchema } from 'drizzle-zod';
import { z } from 'zod';
import {
  activityTypeTable,
  logBestTable,
  logTable,
  recordTable,
  userTable,
} from '~/db/schema.sql';

export type LogBest = typeof logBestTable.$inferSelect;

export type Record = typeof recordTable.$inferSelect;
export type RecordInsert = typeof recordTable.$inferInsert;

export type Log = typeof logTable.$inferSelect;

export type User = typeof userTable.$inferSelect;

export type ActivityType = typeof activityTypeTable.$inferSelect;

export type LogWithActivityType = typeof logTable.$inferSelect & {
  activityType: ActivityType;
};

export const ActivityTypeSchema = createSelectSchema(activityTypeTable);

export const LogSchema = createSelectSchema(logTable, {
  data: z.any(),
});

export const LogWithActivityTypeSchema = LogSchema.extend({
  activityType: ActivityTypeSchema,
});

export const UserSchema = createSelectSchema(userTable);

export type UserType = z.infer<typeof UserSchema>;

export const PublicUserSchema = z.object({
  name: z.string(),
  username: z.string(),
});

export type PublicUser = z.infer<typeof PublicUserSchema>;
