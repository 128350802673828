export const LimitedUseDisclosurePage = () => {
  return (
    <div className="m-auto w-full max-w-xl space-y-6">
      <h1 className="text-2xl md:text-4xl">Limited Use Disclosure</h1>

      <p className="leading-relaxed text-gray-300">
        Base&apos;s use and transfer of information received from Google APIs to
        any other app will adhere to{' '}
        <a
          className="text-blue-500 hover:underline"
          href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
          target="_blank"
        >
          Google API Services User Data Policy
        </a>
        , including the Limited Use requirements.
      </p>
    </div>
  );
};
