'use client';
import { Loader2 } from 'lucide-react';
import { Button } from '~/components';

export function SubmitButton({
  label,
  isLoading,
}: {
  label: string;
  isLoading: boolean;
}) {
  return (
    <Button type="submit" className="w-full" size="md" disabled={isLoading}>
      {isLoading ? <Loader2 className="h-6 w-6 animate-spin" /> : label}
    </Button>
  );
}
