export const formatUsername = (username: string) => {
  // Remove any non-alphanumeric characters
  let formattedUsername = username.replace(/[^a-zA-Z0-9_]/g, '');

  // Ensure the first character is a letter, if it starts with a number, prepend a letter
  if (/^[_]/.test(formattedUsername)) {
    formattedUsername = 'a' + formattedUsername.slice(1);
  }

  if (formattedUsername.length > 20) {
    formattedUsername = formattedUsername.slice(0, 20);
  }

  return formattedUsername.toLowerCase();
};

export const formatUsernameFinal = (username: string) => {
  // Remove any non-alphanumeric characters
  let formattedUsername = username.replace(/[^a-zA-Z0-9_]/g, '');

  // Ensure the first character is a letter, if it starts with a number, prepend a letter
  if (/^[_]/.test(formattedUsername)) {
    formattedUsername = 'a' + formattedUsername.slice(1);
  }

  if (formattedUsername.endsWith('_')) {
    formattedUsername = formattedUsername.slice(0, -1);
  }

  if (formattedUsername.length > 20) {
    formattedUsername = formattedUsername.slice(0, 20);
  }

  return formattedUsername.toLowerCase();
};
