import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth, UserProvider } from '~/auth/hooks';
import { PageLoader } from '~/components';
import { DashboardRedirect, Header } from '~/dashboard/components';

export const DashboardLayout = () => {
  const { isReady, isSignedIn, checkSignedIn } = useAuth();

  useEffect(() => {
    checkSignedIn();
  }, []);

  if (!isReady) return <PageLoader />;

  if (!isSignedIn) return <DashboardRedirect />;

  return (
    <UserProvider>
      <Header />

      <main className="px-8 pb-32 md:px-20 md:pt-10 lg:pb-60">
        <Outlet />
      </main>
    </UserProvider>
  );
};
