import { cookies } from '~/cookie';

export const setRedirectPath = () => {
  const path = window.location.pathname + window.location.search;

  cookies.set('redirect', path);
};

export const getRedirectPath = () => {
  const requestedRedirectPath = cookies.get('redirect') || '/dashboard';
  const redirectPath = requestedRedirectPath.startsWith('/')
    ? requestedRedirectPath
    : `/${requestedRedirectPath}`;

  return redirectPath;
};

export const removeRedirectPath = () => {
  cookies.remove('redirect');
};

export const goToRedirectPath = () => {
  const redirectPath = getRedirectPath();

  removeRedirectPath();

  window.location.href = redirectPath;
};
