import { useQuery } from '@tanstack/react-query';
import { queryLogsMaxPublic } from '~/api';
import { getAppVersion } from '~/base';
import { LogWithActivityType } from '~/types';

export function useLogsMaxQueryPublic({
  username,
  uniqueKey,
}: {
  username: string;
  uniqueKey: string;
}) {
  const { data, isFetched } = useQuery({
    queryKey: ['logsMax', uniqueKey],
    queryFn: async () => {
      const version = await getAppVersion();
      const { logs } = await queryLogsMaxPublic({
        username,
        version,
      });

      return { logs };
    },
    staleTime: 0,
    refetchInterval: 60000,
  });

  const logs = data?.logs as LogWithActivityType[];

  const hasFetched = isFetched;

  return { logs, hasFetched, hasLogs: !!logs?.length };
}
