'use client';
import { useEffect } from 'react';
import { setRedirectPath } from '~/dashboard';

export const DashboardRedirect = () => {
  useEffect(() => {
    setRedirectPath();

    window.location.href = '/sign-in';
  }, []);

  return null;
};
