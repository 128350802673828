'use client';
import { useUser } from '~/auth/hooks';
import { Link } from '~/components';
import { getInitials } from '~/utils';

export const Header = () => {
  const { user } = useUser();

  return (
    <div className="flex gap-6 p-6">
      <Link className="block" href="/dashboard">
        Home
      </Link>

      <Link className="block" href="/dashboard/settings">
        Settings
      </Link>

      <a className="ml-auto" href="/sign-out">
        {getInitials(user.name)}
      </a>
    </div>
  );
};
