import { motion } from 'framer-motion';

export const PageLoader = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <motion.div
        className="h-10 w-10"
        initial={{ opacity: 0, scale: 0 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: {
            duration: 0.5,
            type: 'spring',
          },
        }}
      >
        <div className="loader" />
      </motion.div>
    </div>
  );
};
