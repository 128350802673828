import { PageLoader, Redirect } from '~/components';
import { useParams } from '~/hooks';
import { LogCard } from '~/log/components';
import { useLogsMaxQueryPublic } from '~/log/hooks';
import { usePublicUser } from '~/public/hooks';

export const PublicPage = () => {
  const { username } = useParams<{ username: string }>();
  const { user, isFetched } = usePublicUser(username);

  if (!isFetched) return <PageLoader />;

  if (!user) return <Redirect path="/" />;

  return (
    <div className="space-y-10 p-4">
      <div className="pt-6 text-center text-2xl font-bold">{user.name}</div>

      <Activities username={username} />
    </div>
  );
};

export const Activities = ({ username }: { username: string }) => {
  const { logs, hasFetched, hasLogs } = useLogsMaxQueryPublic({
    username,
    uniqueKey: 'all',
  });

  return (
    <>
      {hasFetched && (
        <div className="record-padding mx-auto max-w-3xl px-4 py-5">
          {hasLogs && (
            <div className="grid gap-3">
              {logs.map((log) => {
                return <LogCard key={log.id} log={log} />;
              })}
            </div>
          )}

          {!hasLogs && (
            <>
              <div className="mt-6 px-4">
                <div className="text-center text-3xl font-semibold">
                  No activity has been logged yet
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
