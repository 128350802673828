import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { getUser } from '~/api';
import { getAuthToken } from '~/auth';
import { User } from '~/types';

const initialState = {
  isReady: false,
  isSignedIn: false,
  user: null as User | null,
};

export const useAuth = create(
  combine(initialState, (set) => ({
    checkSignedIn: async () => {
      try {
        const authToken = await getAuthToken();
        const { user } = await getUser({});
        const isSignedIn = !!(authToken && user);

        set(() => ({ isSignedIn, user, isReady: true }));
      } catch (error) {
        set(() => ({ isReady: true, isSignedIn: false }));
      }
    },
  })),
);
