import { useQuery } from '@tanstack/react-query';
import { getPublicUser } from '~/api';

export function usePublicUser(username: string) {
  const { data: user, isFetched } = useQuery({
    queryKey: ['publicUser'],
    queryFn: async () => {
      const { user } = await getPublicUser({ username });

      return user;
    },
  });

  return { user, isFetched };
}
