import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DateRangeTypeId } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const getTimeFromUnix = (unixTimestamp: number, format?: string) => {
  const userLocale = navigator.language || 'en';

  return dayjs
    .unix(unixTimestamp)
    .format(format ?? userLocale === 'en-US' ? 'h:mm a' : 'H:mm');
};

export const getDateFromUnix = (unixTimestamp: number, format?: string) => {
  const userLocale = navigator.language || 'en';

  return dayjs
    .unix(unixTimestamp)
    .format(format ?? userLocale === 'en-US' ? 'D MMM YY' : 'D MMM YY');
};

export const convertDateStringToUnix = (
  dateString: string | undefined,
  defaultString: string,
) => {
  try {
    if (!dateString) return dayjs(defaultString).unix();

    return dayjs(dateString).unix();
  } catch (error) {
    return dayjs(defaultString).unix();
  }
};

export const convertUnixToDateString = (
  unixTimestamp: number,
  format: string,
) => {
  return dayjs.unix(unixTimestamp).format(format);
};

export const getUnix = (date?: Date) => {
  return dayjs(date).unix();
};

export const formatDuration = (seconds: number): string => {
  // Check if the number of seconds fits into hours
  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const minuteString = minutes > 0 ? ` ${minutes} min` : '';
    return `${hours} hr${hours > 1 ? 's' : ''}${minuteString}`;
  }

  // Check if the number of seconds fits into minutes
  if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const minuteString =
      remainingSeconds === 0
        ? `${minutes} min${minutes !== 1 ? 's' : ''}`
        : `${(seconds / 60).toFixed(1)} mins`;
    return minuteString;
  }

  // Use seconds as the default unit
  return `${seconds} sec${seconds !== 1 ? 's' : ''}`;
};

export const getCurrentUserUnix = () => {
  return dayjs().unix();
};

export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDateRangeByType = (type: DateRangeTypeId) => {
  const ranges: Record<
    DateRangeTypeId,
    { startDate: number; endDate: number }
  > = {
    recent: {
      startDate: dayjs().subtract(30, 'day').startOf('day').unix(),
      endDate: dayjs().endOf('day').unix(),
    },
    today: {
      startDate: dayjs().startOf('day').unix(),
      endDate: dayjs().endOf('day').unix(),
    },
    yesterday: {
      startDate: dayjs().subtract(1, 'day').startOf('day').unix(),
      endDate: dayjs().subtract(1, 'day').endOf('day').unix(),
    },
    last7Days: {
      startDate: dayjs().subtract(7, 'day').startOf('day').unix(),
      endDate: dayjs().endOf('day').unix(),
    },
    last30Days: {
      startDate: dayjs().subtract(30, 'day').startOf('day').unix(),
      endDate: dayjs().endOf('day').unix(),
    },
    last6Months: {
      startDate: dayjs().subtract(6, 'month').startOf('day').unix(),
      endDate: dayjs().endOf('day').unix(),
    },
    lastYear: {
      startDate: dayjs().subtract(1, 'year').startOf('day').unix(),
      endDate: dayjs().endOf('day').unix(),
    },
  };

  return ranges[type];
};

export const getDateRangeTextByType = (
  type: DateRangeTypeId,
  unix?: number,
) => {
  const isToday = unix ? dayjs.unix(unix).isSame(dayjs(), 'day') : false;
  const isYesterday = unix
    ? dayjs.unix(unix).isSame(dayjs().subtract(1, 'day'), 'day')
    : false;

  const texts: Record<
    DateRangeTypeId,
    { label: string | null; empty: string }
  > = {
    recent: {
      label: isToday
        ? 'Today'
        : isYesterday
          ? 'Yesterday'
          : unix
            ? dayjs.unix(unix).format('D MMM')
            : null,
      empty: 'a while',
    },
    today: {
      label: 'Today',
      empty: 'today',
    },
    yesterday: {
      label: 'Yesterday',
      empty: 'yesterday',
    },
    last7Days: {
      label:
        dayjs().subtract(7, 'day').format('D') + '-' + dayjs().format('D MMM'),
      empty: 'last 7 days',
    },
    last30Days: {
      label:
        dayjs().subtract(30, 'day').format('D MMM') +
        ' - ' +
        dayjs().format('D MMM'),
      empty: 'last 30 days',
    },
    last6Months: {
      label:
        dayjs().subtract(6, 'month').format('D MMM') +
        '-' +
        dayjs().format('D MMM'),
      empty: 'last 6 months',
    },
    lastYear: {
      label:
        dayjs().subtract(1, 'year').format('YYYY') +
        '-' +
        dayjs().format('YYYY'),
      empty: 'last year',
    },
  };

  return texts[type];
};
