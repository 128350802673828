import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { getAuthToken } from '~/auth';
import { Header } from '~/auth/components';
import { PageLoader, Redirect } from '~/components';

export const AuthLayout = () => {
  const [isReady, setIsReady] = useState(false);
  const [hasAuthToken, setHasAuthToken] = useState(false);

  useEffect(() => {
    const fn = async () => {
      const authToken = await getAuthToken();

      setIsReady(true);
      setHasAuthToken(!!authToken);
    };

    fn();
  }, []);

  if (!isReady) return <PageLoader />;

  if (hasAuthToken) return <Redirect path="/dashboard" />;

  return (
    <>
      <Header />

      <main className="px-8 pb-32 md:px-20 md:pt-10 lg:pb-60">
        <Outlet />
      </main>
    </>
  );
};
