'use client';
import { motion } from 'framer-motion';
import { Button, Link, Logo } from '~/components';

export const Header = () => {
  return (
    <header className="mx-auto flex h-32 w-full max-w-8xl items-center justify-center px-8 lg:h-40 xl:justify-start 2xl:px-0">
      <Link href="/">
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Logo />
        </motion.div>
      </Link>

      <div className="ml-auto">
        <Link href="/sign-in">
          <Button className="hidden md:block md:w-36" size="md">
            Sign In
          </Button>

          <Button className="md:hidden" size="sm" variant="outline">
            Sign In
          </Button>
        </Link>
      </div>
    </header>
  );
};
