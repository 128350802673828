import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { updateIsPublic, updateUsername } from '~/api';
import { formatUsername, formatUsernameFinal } from '~/auth';
import { useUser } from '~/auth/hooks';
import { Input, Switch } from '~/components';
import { cn } from '~/style';

export const DashboardSettingsPage = () => {
  const { user } = useUser();

  return (
    <div className="space-y-8">
      <UsernameForm />

      <div
        className={cn({
          'pointer-events-none opacity-50': !user.username,
        })}
      >
        <PublicForm />
      </div>
    </div>
  );
};

const UsernameForm = () => {
  const { user, setUser } = useUser();
  const [username, _setUsername] = useState(user.username || '');
  const [error, setError] = useState<string | null>(null);

  const setUsername = (username: string) => {
    _setUsername(formatUsername(username));
  };

  const update = useMutation({
    mutationFn: async (username: string) => {
      setError('');
      const finalUsername = formatUsernameFinal(username);
      setUsername(finalUsername);
      const { error, user } = await updateUsername({ username: finalUsername });

      setUser(user);
      setError(error);
    },
  });

  return (
    <>
      <form
        className="space-y-6"
        onSubmit={(event) => {
          event.preventDefault();

          if (!username) return;

          update.mutate(username);
        }}
      >
        <div className="space-y-2">
          <div className="text-muted-foreground">Username</div>

          <Input
            value={username}
            placeholder="Username"
            onChange={(event) => setUsername(event.target.value)}
            spellCheck={false}
          />
        </div>

        {error && <div className="text-center text-destructive">{error}</div>}
      </form>
    </>
  );
};

const PublicForm = () => {
  const { user, setUser } = useUser();
  const [isPublic, setIsPublic] = useState(user.isPublic);
  const canUpdate = !!user.username;

  const update = useMutation({
    mutationFn: async (isPublic: boolean) => {
      const { user } = await updateIsPublic({ isPublic });

      setUser(user);
    },
  });

  return (
    <div className="space-y-2">
      <div>Public</div>

      <Switch
        disabled={!canUpdate}
        checked={isPublic}
        onCheckedChange={(value) => {
          setIsPublic(value);
          update.mutate(value);
        }}
      />

      <div className="text-muted-foreground">
        {isPublic
          ? 'Your workout stats are public'
          : 'Your workout stats are private'}
      </div>
    </div>
  );
};
