import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import {
  DateRangeTypeId,
  getDateRangeByType,
  getDateRangeTextByType,
} from '~/date';

const initialState = {
  change: 0,
  dateRangeTypes: null as Record<string, DateRangeTypeId> | null,
};

export const useDateRange = create(
  combine(initialState, (set, get) => ({
    setDateRangeTypeId: (uniqueKey: string, typeId: DateRangeTypeId) => {
      const dateRangeTypes = get().dateRangeTypes ?? {};

      dateRangeTypes[uniqueKey] = typeId;
      set({ dateRangeTypes, change: new Date().getTime() });
    },

    getDateRangeTypeId: (uniqueKey: string) => {
      return (get().dateRangeTypes?.[uniqueKey] ?? 'today') as DateRangeTypeId;
    },

    getDateRange: (uniqueKey: string) => {
      const typeid = get().dateRangeTypes?.[uniqueKey] ?? 'today';
      const dateRange = getDateRangeByType(typeid);

      return dateRange;
    },

    getDateRangeText: (uniqueKey: string, unix?: number) => {
      const typeId = get().dateRangeTypes?.[uniqueKey] ?? 'today';
      const text = getDateRangeTextByType(typeId, unix);

      return text;
    },
  })),
);
