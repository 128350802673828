import { numberWithCommas } from '~/utils';

export const hasMetric = (log: any): boolean => {
  if (!log) return false;

  return !!getMetricNumber(log);
};

export const getMetricNumber = (log: any): number => {
  if (!log) return 0;

  const key = getMetricKey(log);

  switch (key) {
    case 'weight':
      return log?.weight ?? 0;
    case 'count':
      return log?.count ?? 0;
    default:
      return 0;
  }
};

export const getMetricText = (log: any): string => {
  if (!log) return '--';

  const key = getMetricKey(log);

  switch (key) {
    case 'weight':
      return `${numberWithCommas(log?.weight ?? 0)} ${log?.weightUnit ?? ''}`;

    default:
      return numberWithCommas(log?.count ?? 0);
  }
};

export const getMetricSuffix = (log: any): string => {
  if (!log) return '';

  const key = getMetricKey(log);

  switch (key) {
    case 'weight':
      return log?.weightUnit ?? '';
    default:
      return '';
  }
};

export const getMetricKey = (log: any): string => {
  if (!log) return 'count';

  if (log.weight) return 'weight';

  return 'count';
};

export const getExactMetricKey = (log: any) => {
  if (!log) return 'count';

  const key = getMetricKey(log);

  switch (key) {
    case 'weight':
      if (log.weightUnit === 'kg') return 'weightKg';
      if (log.weightUnit === 'lbs') return 'weightLbs';
      if (log.weightUnit === 'st') return 'weightSt';

      return 'weightKg';
    case 'count':
      return 'count';
    default:
      return 'count';
  }
};
