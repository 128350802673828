import { relations } from 'drizzle-orm';
import {
  boolean,
  doublePrecision,
  index,
  integer,
  json,
  pgTable,
  text,
  uniqueIndex,
  varchar,
} from 'drizzle-orm/pg-core';

export const userTable = pgTable('users', {
  id: text().primaryKey().notNull(),
  created: integer().notNull(),
  updated: integer().notNull(),
  email: varchar({ length: 255 }).notNull(),
  name: varchar({ length: 255 }).notNull(),
  active: boolean().default(true),
  isPublic: boolean('is_public').default(false).notNull(),
  profile: text(),
  username: text(),
});

export const userRelations = relations(userTable, () => ({}));

export const recordTable = pgTable('records', {
  id: text().primaryKey().notNull(),
  created: integer().notNull(),
  updated: integer().notNull(),
  userId: varchar('user_id', { length: 255 }).notNull(),
  userTimezone: varchar('user_timezone', { length: 255 })
    .default('UTC')
    .notNull(),
  userDate: integer('user_date').notNull(),
  data: json(),
  prompt: text(),
});

export const logTable = pgTable(
  'logs',
  {
    id: text().primaryKey().notNull(),
    created: integer().notNull(),
    updated: integer().notNull(),
    date: integer().notNull(),
    userId: varchar('user_id', { length: 255 }).notNull(),
    recordId: varchar('record_id', { length: 255 }).notNull(),
    data: json(),
    prompt: text().notNull(),
    category: varchar({ length: 255 }).notNull(),
    type: varchar({ length: 255 }).notNull(),
    disabled: boolean().default(false).notNull(),
    activityTypeId: varchar('activity_type_id', { length: 255 }),
    title: text(),
    description: text(),
    weightUnit: varchar('weight_unit', { length: 255 }),
    weight: doublePrecision(),
    weightKg: doublePrecision('weight_kg'),
    weightLbs: doublePrecision('weight_lbs'),
    weightSt: doublePrecision('weight_st'),
    distanceUnit: varchar('distance_unit', { length: 255 }),
    distance: doublePrecision(),
    durationSeconds: integer('duration_seconds'),
    count: integer(),
    calories: integer(),
  },
  (table) => {
    return {
      indexActivityTypeId: index('logs_index_activity_type_id').on(
        table.activityTypeId
      ),
    };
  }
);

export const logRelations = relations(logTable, ({ one, many }) => ({
  activityType: one(activityTypeTable, {
    fields: [logTable.activityTypeId],
    references: [activityTypeTable.id],
  }),
  logsBest: many(logBestTable),
}));

export const logBestTable = pgTable(
  'logs_best',
  {
    id: text().primaryKey().notNull(),
    created: integer().notNull(),
    updated: integer().notNull(),
    date: integer().notNull(),
    userId: varchar('user_id', { length: 255 }).notNull(),
    activityTypeId: varchar('activity_type_id', { length: 255 }).notNull(),
    logId: varchar('log_id', { length: 255 }).notNull(),
  },
  (table) => {
    return {
      userIdActivityTypeIdKey: uniqueIndex(
        'logs_best_user_id_activity_type_id_key'
      ).using(
        'btree',
        table.userId.asc().nullsLast().op('text_ops'),
        table.activityTypeId.asc().nullsLast().op('text_ops')
      ),
      logsMaxIndexActivityTypeId: index(
        'logs_max_index_activity_type_id'
      ).using('btree', table.activityTypeId.asc().nullsLast().op('text_ops')),
      logsMaxIndexLogId: index('logs_max_index_log_id').using(
        'btree',
        table.logId.asc().nullsLast().op('text_ops')
      ),
    };
  }
);

export const logBestRelations = relations(logBestTable, ({ one }) => ({
  log: one(logTable, {
    fields: [logBestTable.logId],
    references: [logTable.id],
  }),
  activityType: one(activityTypeTable, {
    fields: [logBestTable.activityTypeId],
    references: [activityTypeTable.id],
  }),
}));

export const activityTypeTable = pgTable(
  'activity_types',
  {
    id: text().primaryKey().notNull(),
    created: integer().notNull(),
    updated: integer().notNull(),
    nameId: varchar('name_id', { length: 255 }).notNull(),
    name: varchar({ length: 255 }).notNull(),
    goals: text(),
    category: varchar({ length: 255 }),
  },
  (table) => {
    return {
      nameIdKey: uniqueIndex('activity_types_name_id_key').using(
        'btree',
        table.nameId.asc().nullsLast().op('text_ops')
      ),
    };
  }
);

export const activityTypeRelations = relations(
  activityTypeTable,
  ({ many }) => ({
    logs: many(logTable),
    logBest: many(logBestTable),
  })
);

export const tables = {
  user: userTable,
  record: recordTable,
  log: logTable,
  logBest: logBestTable,
  activityType: activityTypeTable,
};

export const schema = {
  ...tables,

  userRelations,
  logRelations,
  logBestRelations,
  activityTypeRelations,
};

export type TableType = keyof typeof tables;
