'use client';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from '~/api';
import { SubmitButton } from '~/auth/components';
import { Input, Link } from '~/components';

export const SignUpPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);
  const navigate = useNavigate();

  const submit = useMutation({
    mutationFn: async () => {
      const { error } = await signUp({ name, email });

      if (error) {
        setError(error);
      } else {
        setIsWaiting(true);
        navigate('/sign-up/code');
      }
    },
  });

  return (
    <div className="mx-auto max-w-md pt-10">
      <div className="space-y-6">
        <form
          className="space-y-6"
          onSubmit={(event) => {
            event.preventDefault();

            if (!name || !email) return;

            submit.mutate();
          }}
        >
          <div className="space-y-2">
            <Input
              placeholder="Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />

            <Input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />

            {error && (
              <div className="text-center text-destructive">{error}</div>
            )}
          </div>

          <SubmitButton
            label="Sign Up"
            isLoading={submit.isPending || isWaiting}
          />
        </form>

        <div className="text-center text-sm">
          <span className="text-muted-foreground">
            Already have an account?
          </span>
          <span>&nbsp;&nbsp;</span>
          <Link href="/sign-in">Sign In</Link>
        </div>
      </div>
    </div>
  );
};
