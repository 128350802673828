export const formatText = (text: string | null | undefined) => {
  if (!text) return '';

  return text.trim().replace(/\n/g, '<br/>');
};

export const pluralise = (word: string, count: number) => {
  return count === 1 ? word : `${word}s`;
};

export const getInitials = (name: string) => {
  const parts = name.trim().split(/\s+/);

  let initials = parts.map((part) => part[0]?.toUpperCase());

  if (parts.length > 2) {
    initials = [initials[0], initials[initials.length - 1]];
  }

  return initials.join('');
};

export const getFirstName = (name: string) => {
  return name.trim().split(/\s+/)[0];
};
