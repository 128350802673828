'use client';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { verifyAuthCode } from '~/api';
import { setAuthToken } from '~/auth';
import { SubmitButton } from '~/auth/components';
import { Input } from '~/components';
import { goToRedirectPath } from '~/dashboard';

export const CodePage = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isWaiting, setIsWaiting] = useState(false);

  const submit = useMutation({
    mutationFn: async () => {
      const { error, authToken } = await verifyAuthCode({ code });

      setError(error);

      if (!authToken) {
        return;
      }

      setIsWaiting(true);
      await setAuthToken(authToken);
      goToRedirectPath();
    },
  });

  return (
    <div className="mx-auto max-w-md pt-10">
      <form
        className="space-y-6"
        onSubmit={(event) => {
          event.preventDefault();

          if (!code) return;

          submit.mutate();
        }}
      >
        <div className="space-y-2">
          <Input
            type="number"
            placeholder="Enter code"
            required
            value={code}
            onChange={(event) => setCode(event.target.value)}
            autoFocus
          />

          {error && <div className="text-center text-destructive">{error}</div>}
        </div>

        <SubmitButton
          label="Submit"
          isLoading={submit.isPending || isWaiting}
        />
      </form>
    </div>
  );
};
