export const DeleteAccountPage = () => {
  return (
    <div className="m-auto w-full max-w-xl space-y-6">
      <h1 className="text-2xl md:text-4xl">Delete Account</h1>

      <p className="leading-relaxed text-muted-foreground">
        You can delete your Base account in the app by selecting the menu icon
        in the top right corner, then select "Delete Account".
        <br />
        <br />
        All of your data, including your account and workout activities, will be
        permanently deleted from our servers and cannot be recovered.
      </p>
    </div>
  );
};
