'use client';
import { motion } from 'framer-motion';
import { Link, Logo } from '~/components';

export const Header = () => {
  return (
    <header className="mx-auto flex h-32 w-full max-w-8xl items-center justify-center px-8 lg:h-40 2xl:px-0">
      <Link href="/">
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Logo />
        </motion.div>
      </Link>
    </header>
  );
};
